import React from "react"

import styled from "styled-components"
import Mapa from "./mapa"
import Clinicas from "./clinicas"

const MapaForm = ({ data }) => {
  
  return(
    <StyledMapa>
      <Clinicas data={data}/>
      <Mapa id="mapForm"/>
    </StyledMapa>
  )
}

export default MapaForm

const StyledMapa = styled.div`
  position: relative;
  /* margin: 6vw 0;
  .clinicas{
    margin: 5vw 7.5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3vw;
    grid-column-gap: 3vw;
  } */
`