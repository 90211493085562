import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import MapaForm from "../components/mapaForm/mapaForm"
import MapaFormMobile from "../components/mapaForm/mapaFormMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const ContactosPage = ({ data }) => (
  <Layout>
    <SEO title="Contactos | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <MapaFormMobile data={data.contactoJson.clinicas} />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <MapaForm data={data.contactoJson.clinicas} />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default ContactosPage

export const Json = graphql`
  query contactos {
    contactoJson {
      clinicas {
        title
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        morada
        email
        tel
        txt
        mapa
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
