import React, { useState, useRef } from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import face from "../../images/toolbar/facebook.svg"
import insta from "../../images/toolbar/instagram.svg"
import tube from "../../images/toolbar/youtube.svg"

import fbTrack from "../../custom/fbTrack"
import { ConsentGtag } from "../utils/consentGtag"
const axios = require("axios")

const Mapa = ({ id }) => {
  const form = useRef()
  const [response, setResponse] = useState("")

  function doSubmit(e) {
    ConsentGtag()
    e.preventDefault()
    var formData = new FormData(form.current)
    formData.append("name", document.querySelector("#nomeMSG").value)
    formData.append("email", document.querySelector("#emailMSG").value)
    formData.append("phone", document.querySelector("#telefoneMSG").value)
    formData.append("subject", document.querySelector("#assuntoMSG").value)
    formData.append("message", document.querySelector("#mensagemMSG").value)
    formData.append("contacto", true)
    axios
      .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? success()
          : setResponse(res.data)
      )
  }
  const success = () => {
    setResponse("Mensagem enviada com sucesso!")
    fbTrack("trackCustom", "Envio de formulário - Página de Contacto")
    if (typeof window !== "undefined") {
      window.gtag("event", "form_contacto", {
        form: "Formulário de Contacto"
      })
    }
    form.current.reset()
  }

  return (
    <StyledMapa id={id}>
      <div className="titleIcons">
        <div className="titles">
          <p className={font.rB + " title"}>SIGA-NOS NAS REDES SOCIAIS</p>
          <p className={font.rSB + " subtitle"}>Fique a par das novidades.</p>
        </div>
        <div className="icons">
          <Link to="https://www.facebook.com/carreiradentalclinic">
            <img src={face} alt="Logo do Facebook" />
          </Link>
          <Link to="https://www.instagram.com/carreiradentalclinic/">
            <img src={insta} alt="Logo do Instagram" />
          </Link>
          <Link to="https://www.youtube.com/channel/UCEtWAWV_Vvhu7jvuDQ081sQ">
            <img src={tube} alt="Logo do Youtube" />
          </Link>
        </div>
      </div>

      <div className="mapaForm">
        <div className="mapa">
          <iframe
            src="https://snazzymaps.com/embed/185031"
            height="100%"
            width="100%"
            style={{ border: "none", height: "100%", width: "100%" }}
            title="Mapa"
          ></iframe>
        </div>

        <div className="form">
          <p className={font.rB + " formTitle"}>
            CONTACTE-NOS
            <span className="smallUnderline"></span>
          </p>
          <form
            method="POST"
            name="contacto"
            action="contactar.php"
            id="contactForm"
            ref={form}
          >
            <p className={font.rSB + " label"}>NOME*</p>
            <input
              type="text"
              name="nomeMSG"
              id="nomeMSG"
              className={font.rSB}
            />
            <br />
            <p className={font.rSB + " label"}>EMAIL*</p>
            <input
              type="text"
              name="emailMSG"
              id="emailMSG"
              className={font.rSB}
            />
            <br />
            <p className={font.rSB + " label"}>TELEFONE*</p>
            <input
              type="text"
              name="telefoneMSG"
              id="telefoneMSG"
              className={font.rSB}
            />
            <br />
            <p className={font.rSB + " label"}>ASSUNTO</p>
            <input
              type="text"
              name="assuntoMSG"
              id="assuntoMSG"
              className={font.rSB}
            />
            <br />
            <p className={font.rSB + " label"}>MENSAGEM</p>
            <textarea
              name="mensagem"
              form="contacto"
              id="mensagemMSG"
              className={font.rSB}
            />
          </form>
          <p className={font.rR + " disclaimer"}>
            Este formulário de contacto corresponde ao procedimento normal de
            envio de um email diretamente para a nossa caixa de correio. Os seus
            dados não são armazenados nem utilizados para qualquer outro fim.
          </p>
          <Button
            type="submit"
            form="contactForm"
            name="submit"
            value="submit"
            className={font.rSB + " button btnEnviar"}
            onClick={doSubmit}
          >
            Enviar
          </Button>
          <div className={font.rM + " response"} id={response === "Mensagem enviada com sucesso!" ? "contacto-sucesso" : "contacto-fail"}>{response}</div>
        </div>
      </div>
    </StyledMapa>
  )
}

export default Mapa

const StyledMapa = styled.div`
  /* background-color: #e5e0db; */
  display: flex;
  flex-direction: column;
  margin-bottom: 5vw;
  .titleIcons {
    position: relative;
    color: #231f20;
    padding: 3.2em calc(52px + 233 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      padding: 3.2em 285px;
    }
    letter-spacing: 0.2em;
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-size: calc(20px + 20 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 40px;
    }
    letter-spacing: 0.15em;
  }
  .subtitle {
    font-size: calc(18px + 10 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 28px;
    }
    letter-spacing: 4.2px;
  }
  .icons {
    position: relative;
    display: flex;
  }
  .icons img {
    width: calc(38px + 42 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      width: 80px;
    }
    margin-left: 3em;
  }
  .mapaForm {
    background-color: #eeeae6;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
  }
  .mapa {
    height: 100%;
    width: 100%;
  }
  .form {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 80px;
    @media only screen and (max-width: 1200px) {
      padding: 40px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .formTitle {
    font-size: calc(20px + 6 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 26px;
    }
    letter-spacing: 0.1em;
    margin-bottom: 2em;
    position: relative;
  }
  .label {
    font-size: calc(12px + 2 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 14px;
    }
    letter-spacing: 0.2em;
    margin-bottom: 1%;
  }
  .form input[type="text"] {
    background-color: #eeeae6;
    outline: none;
    border: 1px solid #b7a99a;
    width: 100%;
    margin-bottom: 2%;
    font-size: calc(14px + 8 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    height: 1.8em;
    line-height: 1.8em;
  }
  #mensagemMSG {
    background-color: #eeeae6;
    outline: none;
    border: 1px solid #b7a99a;
    margin-bottom: 2%;
    width: 100%;
    height: 5em;
    font-size: calc(14px + 8 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    resize: none;
  }
  .disclaimer {
    font-size: calc(10px + 6 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 16px;
    }
    margin-bottom: 4%;
    color: #808080;
  }
  .btnEnviar {
    height: 2.5em;
    width: fit-content;
    padding: 0 calc(40px + 30 * (100vw - 769px) / 1151);
    background-color: #b7a99a;
    color: #ffffff;
    border: none;
    line-height: 2em;
    font-size: calc(16px + 10 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 26px;
      padding: 0 70px;
    }
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .smallUnderline {
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    top: 1.3em;
    left: 0;
  }
  .response {
    font-size: calc(20px + 10 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 30px;
    }
  }
`
