import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import ponto from "../../images/point.svg"

import { handleClick } from "../../custom/gtag-click"

const Clinicas = ({ data }) => {
  // Tirar a última clínica, de Oliveira de Azemeis
  const filteredData = data.filter(
    ele => ele.title !== "CLÍNICA DE OLIVEIRA DE AZEMÉIS!"
  )

  return (
    <StyledClinicas>
      {filteredData.map((data, i) => (
        <div className="clinicas" key={"clinica" + i}>
          <div className="inner">
            <div className="clinicaFace">
              <div className="clinica">
                <p className={font.rSB + " title"}>
                  {data.title}
                  {i === 0 && <span className={font.rSB + " sede"}>SEDE</span>}
                </p>
                <img
                  src={ponto}
                  alt="Ponto de Localização"
                  className="pointImg"
                />
              </div>
            </div>

            <div className="clinicaContainer">
              <div className="clinica">
                <p className={font.rSB + " title"}>
                  {data.title}
                  {i === 0 && <span className={font.rSB + " sede"}>SEDE</span>}
                  {/*i === 4 && <span className={font.rSB + " sede"}>BREVEMENTE</span>*/}
                </p>
                <img
                  src={ponto}
                  alt="Ponto de Localização"
                  className="pointImg"
                />
                <div className={font.rM + " text"}>
                  <p>{data.morada[0]}</p>
                  <p>{data.morada[1]}</p>
                </div>
                <div className={font.rM + " text"}>
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </div>
                <div className={font.rM + " numbers"}>
                  <a
                    onClick={() => handleClick(`tel:+351${data.tel[0]}`, data.title)}
                    style={{ margin: "0 auto" }}>{data.tel[0]}<small style={{ fontSize: "15px" }}>·¹</small></a>
                  <div className="break" />
                  <a
                    onClick={() => handleClick(`tel:+351${data.tel[1]}`, data.title)}
                    style={{ margin: "0 auto" }}>{data.tel[1]}<small style={{ fontSize: "15px" }}>·²</small></a>

                </div>
                <div style={{ marginTop: "0px", textAlign: "center" }}>
                  <small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹«Chamada para a rede móvel nacional»</small><br></br>
                  <small style={{ color: "darkgray", fontSize: "15px" }}>·²«Chamada para a rede fixa nacional»</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </StyledClinicas>
  )
}

export default Clinicas

const StyledClinicas = styled.div`
  position: relative;
  padding: 3vw 7.5vw;
  background-color: #eeeae6;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 3vw;
  grid-column-gap: 3vw;
  a{
    cursor:pointer;
  }
  .clinicas {
    margin: auto;
    width: 100%;
    background-color: transparent;
    perspective: 2000px;
    .inner {
      box-shadow: 0 4px 8px 0 rgba(183, 169, 154, 0.2);
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }
    .clinicaContainer,
    .clinicaFace {
      position: relative;
      width: 100%;
      height: 26vw;
      min-height: 275px;
      background-color: #fff;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      .clinica {
        position: relative;
        background-color: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: calc(0px + 40 * (100vw - 769px) / 1151)
          calc(0px + 10 * (100vw - 769px) / 1151);
        padding-top: 40px;
        @media only screen and (min-width: 1920px) {
          padding: 40px 10px;
        }
        .sede {
          font-size: calc(12px + 4 * (100vw - 769px) / 1151);
          @media only screen and (min-width: 1920px) {
            font-size: 16px;
          }
          letter-spacing: 0.2em;
          color: #b7a99a;
          position: absolute;
          top: -40px;
          left: 50%;
          transform: translateX(-50%);
        }
        .title {
          position: relative;
          text-align: center;
          font-size: calc(14px + 10 * (100vw - 769px) / 1151);
          @media only screen and (min-width: 1920px) {
            font-size: 24px;
          }
          letter-spacing: 0.1em;
          color: #231f20;
        }
        .pointImg {
          width: 10%;
        }
        .text {
          font-size: calc(12px + 12 * (100vw - 769px) / 1151);
          @media only screen and (min-width: 1920px) {
            font-size: 24px;
          }
          letter-spacing: 0.1em;
          color: #808080;
          text-align: center;
        }
        .numbers {
          font-size: calc(11px + 12 * (100vw - 769px) / 1151);
          
          letter-spacing: 0.1em;
          color: #808080;
          text-align: center;
        }
        .verMaisBtn {
          background-color: #b7a99a;
          color: #fff;
          font-size: calc(12px + 3 * (100vw - 769px) / 1151);
          padding: calc(6px + 4 * (100vw - 769px) / 1151)
            calc(15px + 5 * (100vw - 769px) / 1151);
          @media only screen and (min-width: 1920px) {
            font-size: 15px;
            padding: 10px 20px;
          }
          letter-spacing: 0.2em;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%);
        }
        .break {
          width: 30px;
          height: calc(3px + 7 * (100vw - 769px) / 1151);
          @media only screen and (min-width: 1920px) {
            height: 10px;
          }
          background-color: #b7a99a;
          margin: 9px auto 5px;
        }
      }
    }
    .clinicaContainer {
      position: absolute;
      top: 0;
      transform: rotateY(180deg);
    }
    .clinicaFace {
      transform: rotateY(0deg);
    }
  }
  .clinicas:hover .inner {
    transform: rotateY(180deg);
  }
`
