import React, { useState } from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import ponto from "../../images/point.svg"

import { handleClick } from "../../custom/gtag-click"

const Clinicas = ({ data }) => {
  const [index, setIndex] = useState(0)

  // Tirar a última clínica, de Oliveira de Azemeis
  const filteredData = data.filter(
    ele => ele.title !== "CLÍNICA DE OLIVEIRA DE AZEMÉIS!"
  )

  return (
    <StyledClinicas>
      <div className="clinicas">
        {filteredData.map((data, i) => (
          <Button
            styleless
            className="containerBtn"
            onClick={() => {
              setIndex(i)
            }}
          >
            <div
              className="clinicaContainer"
              key={"clinica" + i}
              style={{
                height:
                  index === i
                    ? "calc(240px + 120 * (100vw - 320px) / 448)"
                    : "calc(80px + 1em)",
              }}
            >
              <div className="clinica">
                <p className={font.rSB + " title"}>
                  {data.title}
                  {i === 0 && (
                    <span
                      className={
                        index === i
                          ? font.rSB + " sede"
                          : font.rSB + " sede fade"
                      }
                    >
                      SEDE
                    </span>
                  )}

                </p>
                <img
                  src={ponto}
                  alt="Ponto de Localização"
                  className={index === i ? "pointImg" : "pointImg fade"}
                />
                <div
                  className={
                    index === i ? font.rM + " text" : font.rM + " text fade"
                  }
                >
                  <p>{data.morada[0]}</p>
                  <p>{data.morada[1]}</p>
                </div>
                <div
                  className={
                    index === i
                      ? font.rM + " text email"
                      : font.rM + " text email fade"
                  }
                >
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </div>
                <div
                  className={
                    index === i
                      ? font.rM + " numbers"
                      : font.rM + " numbers fade"
                  }
                >
                  <a
                    onClick={() => handleClick(`tel:+351${data.tel[0]}`, data.title)}
                  >{data.tel[0]}<small style={{ fontSize: "12px" }}>·¹</small></a>
                  <div className="break" />
                  <a onClick={() => handleClick(`tel:+351${data.tel[1]}`, data.title)}>{data.tel[1]}<small style={{ fontSize: "12px" }}>·²</small></a>
                  <div style={{ marginTop: "14px", textAlign: "center" }}>
                    <small style={{ color: "darkgray", fontSize: "9px", lineHeight: "2px" }}>·¹«Chamada para a rede móvel nacional»</small><br></br>
                    <small style={{ color: "darkgray", fontSize: "9px" }}>·²«Chamada para a rede fixa nacional»</small>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className={
                index === i
                  ? font.rSB + " verMaisBtn fade"
                  : font.rSB + " verMaisBtn"
              }
              onClick={() => {
                setIndex(i)
              }}
            >
              VER MAIS
            </Button>
          </Button>
        ))}
      </div>
    </StyledClinicas>
  )
}

export default Clinicas

const StyledClinicas = styled.div`
  position: relative;
  padding: 3vw 0;
  background-color: #eeeae6;
  a{
    cursor:pointer;
  }
  .fade {
    opacity: 0;
  }
  .clinicas {
    margin: 5vw 7.5%;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 3vw;
    grid-column-gap: 3vw;
    .clinicaContainer {
      position: relative;
      width: 100%;
      font-size: calc(14px + 10 * (100vw - 769px) / 1151);
      overflow: hidden;
      transition: height 350ms;
      * {
        transition: opacity 350ms;
      }
      .clinica {
        position: relative;
        background-color: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 100%;
        height: 100%;
        padding: 34px 10px;
        .sede {
          font-size: calc(12px + 4 * (100vw - 320px) / 448);
          letter-spacing: 0.2em;
          color: #b7a99a;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .title {
          position: relative;
          text-align: center;
          font-size: calc(12px + 8 * (100vw - 320px) / 448);
          letter-spacing: 0.1em;
          color: #231f20;
        }
        .pointImg {
          width: calc(20px + 12 * (100vw - 320px) / 448);
          margin: calc(10px + 5 * (100vw - 320px) / 448) 0
            calc(10px + 5 * (100vw - 320px) / 448);
        }
        .text {
          font-size: calc(12px + 6 * (100vw - 320px) / 448);
          letter-spacing: 0.1em;
          color: #808080;
          text-align: center;
        }
        .email {
          margin: calc(10px + 10 * (100vw - 320px) / 448) 0
            calc(0px + 15 * (100vw - 320px) / 448);
            a{
              margin:0 auto;
            }
        }
        .numbers {
          font-size: calc(12px + 8 * (100vw - 320px) / 448);
          letter-spacing: 0.1em;
          color: #808080;
          text-align: center;
          a{
              margin:0 auto;
          }
        }
        .break {
          width: calc(20px + 10 * (100vw - 320px) / 448);
          height: calc(3px + 1 * (100vw - 320px) / 448);
          background-color: #b7a99a;
          margin: calc(5px + 5 * (100vw - 320px) / 448) auto;
        }
      }
    }
    .containerBtn {
      position: relative;
      .verMaisBtn {
        background-color: #b7a99a;
        color: #fff;
        font-size: calc(12px + 3 * (100vw - 769px) / 1151);
        padding: calc(6px + 4 * (100vw - 769px) / 1151)
          calc(15px + 5 * (100vw - 769px) / 1151);
        letter-spacing: 0.2em;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
      }
    }
  }
`
